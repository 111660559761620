import React from "react"
import { Link } from "gatsby"

import styled from 'styled-components'
import { Navbar } from 'react-bootstrap';  

import logoWhite from '../../assets/images/parsity-logo-black.png'

const StyledNavbar = styled(Navbar)`
  .logo img {
    height: 60px;
    margin-bottom: 0;
    margin-top: 20px;
    padding-left: 20px;
  }
  .NavItem {
    margin-right: 25px
  }
`;

export default function PSNavBar() {
  return (
    <StyledNavbar expand="lg">
      <Link to='/'>
        <StyledNavbar.Brand className="logo">
          <img className='black-logo' src={logoWhite} alt=''></img>
        </StyledNavbar.Brand>
      </Link>
    </StyledNavbar>
  )
}
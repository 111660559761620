// use `content-index.js` at launch
import React from "react"
import Layout from "../components/layouts/courseLandingLayout"
import styled from "styled-components"
import { Container, Col, Row } from "react-bootstrap"
import Navbar from "../components/navbars/courseLandingNavbarGeneral"
import BrandBox from "../components/brandBox/brandBoxWidget"

const ThankYouBox = styled.div`
  p {
    padding-top: 30px;
  }
`

const Thanks = () => {
  return (
    <Layout>
      <Navbar />
      <Container>
        <Row>
          <Col md={12}>
            <BrandBox>
              <ThankYouBox>
                <h1>Thank you!</h1>
                <p>We've got your spot saved!</p>
              </ThankYouBox>
            </BrandBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Thanks
